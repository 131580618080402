import network from "@/api/network";

export default class General {
  static async getProjects() {
    return await network.get('project/list', null, {});
  }

  static async getMemberGroups() {
    return await network.get('group/promo/list', null, {});
  }

  static async editMemberGroups(data) {
    return await network.put('group/promo/many', data, {});
  }
}