import network from "@/api/network";

export default class Points {
  static async getPointsFlow(page, perPage, data) {
    return await network.post(`points/transaction/movement/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportPointsFlow(data, config) {
    return await network.post("points/transaction/movement/list/filter/download/report", data, config);
  }

  static async importPoints(data) {
    return await network.post("points/import", data, {});
  }

  static async getPointsPerPeriod(page, perPage, data) {
    return await network.post(`points/transaction/range/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportPointsPerPeriod(data, config) {
    return await network.post("points/transaction/range/list/filter/download/report", data, config);
  }
}
