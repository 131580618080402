import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import api from '@/api'
import moment from "moment";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    preloader: 0,
    projects: null,
    currentProject: JSON.parse(localStorage.getItem('project') || null),
    memberGroups: [],
    startDate: moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    pageSizes: [
      { name: "10", value: 10 },
      { name: "25", value: 25 },
      { name: "50", value: 50 },
      // { name: "100", value: 100 },
      // { name: "Все", value: 9999999999 },
    ],
    physicalPrizes: null,
  },
  getters: {
    PRELOADER(state) {
      return state.preloader;
    },
    GET_PROJECTS(state) {
      return state.projects;
    },
    GET_CURRENT_PROJECT(state) {
      return state.currentProject;
    },
    GET_MEMBER_GROUPS(state) {
      return state.memberGroups;
    },
    GET_START_DATE(state) {
      return state.startDate;
    },
    GET_END_DATE(state) {
      return state.endDate;
    },
    GET_PAGE_SIZES(state) {
      return state.pageSizes;
    },
    GET_PHYSICAL_PRIZES(state) {
      return state.physicalPrizes;
    }
  },
  mutations: {
    SET_PRELOADER_INCR(state) {
      state.preloader++;
    },
    SET_PRELOADER_DECR(state) {
      state.preloader--;
    },
    SET_PROJECTS(state, payload) {
      state.projects = payload;
    },
    SET_CURRENT_PROJECT(state, payload) {
      localStorage.setItem("project", JSON.stringify(payload));
      state.currentProject = payload;
    },
    SET_MEMBER_GROUPS(state, payload) {
      state.memberGroups = payload;
    },
    SET_START_DATE(state, payload) {
      state.startDate = payload;
    },
    SET_END_DATE(state, payload) {
      state.endDate = payload;
    },
    SET_PAGE_SIZES(state, payload) {
      state.pageSizes = payload;
    },
    SET_PHYSICAL_PRIZES(state, payload) {
      state.physicalPrizes = payload;
    }
  },
  actions: {
    async fetchProjects({commit}) {
      commit('SET_PRELOADER_INCR');
      let res = await api.general.getProjects();
      if (res.isSuccess) {
        commit("SET_PROJECTS", res.result);
        if (JSON.parse(localStorage.getItem('project')) === null) {
          commit("SET_CURRENT_PROJECT", res.result[0].id);
        }
      }
      commit('SET_PRELOADER_DECR');
      return res;
    },
    async fetchMemberGroups({commit}) {
      commit('SET_PRELOADER_INCR');
      let res = await api.general.getMemberGroups();
      if (res.isSuccess) {
        commit("SET_MEMBER_GROUPS", res.result);
      }
      commit('SET_PRELOADER_DECR');
      return res;
    },
    async editMemberGroups({commit, dispatch}, payload) {
      commit('SET_PRELOADER_INCR');
      let res = await api.general.editMemberGroups(payload);
      commit('SET_PRELOADER_DECR');
      return res
    },
    updateStartDate(context, payload) {
      context.commit("SET_START_DATE", payload);
    },
    updateEndDate(context, payload) {
      context.commit("SET_END_DATE", payload);
    },
    updateCurrentProject(context, payload) {
      context.commit("SET_CURRENT_PROJECT", payload);
    },
    preloaderOn({ commit }) {
      commit("SET_PRELOADER_INCR");
    },
    preloaderOff({ commit }) {
      commit("SET_PRELOADER_DECR");
    },
    async fetchPhysicalPrizes({commit}, payload) {
      commit('SET_PRELOADER_INCR');
      let res = await api.delivery.getPhysicalPrizes(payload);
      if(res.isSuccess) {
          commit("SET_PHYSICAL_PRIZES", res.result.items);
      }
      commit("SET_PRELOADER_DECR");
      return res
    }
  },
  modules: {
    user
  },
});

export default store