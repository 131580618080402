import network from "@/api/network";

export default class Mailout {
  static async getTemplates(page, perPage, data) {
    return await network.post(`mailing-templates/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async addTemplate(data) {
    return await network.post("mailing-templates", data, {});
  }

  static async editTemplate(data) {
    return await network.post("mailing-templates", data, {});
  }

  static async deleteTemplate(data) {
    return await network.delete("mailing-templates", data, {});
  }

  static async getMail(page, perPage, data) {
    return await network.post(`mailing/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async addMail(data) {
    return await network.post("mailing", data, {});
  }

  static async editMail(data) {
    return await network.post("mailing", data, {});
  }

  static async deleteMail(data) {
    return await network.delete("mailing", data, {});
  }

  static async sendMail(data) {
    return await network.post("mailing/send", data, {});
  }
}
