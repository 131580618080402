import network from "@/api/network";

export default class Articles {
  static async getArticles(page, perPage, data) {
    return await network.post(`articles/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async addArticlesFile(data) {
    return await network.post("articles/import/csv", data, {});
  }

  static async addArticle(data) {
    return await network.post("articles", data, {});
  }

  static async editArticle(data) {
    return await network.put("articles", data, {});
  }

  static async deleteArticle(data) {
    return await network.delete("articles", data, {});
  }

  static async exportArticles(data, config) {
    return await network.post("articles/export/csv", data, config);
  }
}
