import network from "@/api/network";

export default class Taxes {
  static async getNextPeriod(data) {
    return await network.get("taxes/income/period/next", data, {});
  }

  static async createReport(data) {
    return await network.post("taxes/income/create", data, {});
  }

  static async getTaxReports(page, perPage, data) {
    return await network.get(`taxes/income/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async downloadReport(data, config) {
    return await network.get("taxes/income/report/export", data, config);
  }

  static async getPersonalData(page, perPage, data) {
    return await network.get(`taxes/personal/statuses/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportPersonalData(data, config) {
    return await network.get("taxes/personal/statuses/list/filter/export", data, config);
  }

  static async approvePersonalData(data) {
    return await network.post("taxes/personal/statuses/accept", data, {});
  }

  static async denyPersonalData(data) {
    return await network.post("taxes/personal/statuses/revoke", data, {});
  }

  static async updatePDByFile(data) {
    return await network.post("taxes/personal/import/csv", data, {});
  }

  static async getPDQueueStatus(id) {
    return await network.get(`tasks/${id}/import/status`, null, {});
  }
}
