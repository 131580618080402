import network from "@/api/network";

export default class Prize {
  static async getPrize(page, perPage, data) {
    return await network.post(`prize/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async modifyPrize(data) {
    return await network.post('prize', data, {});
  }

  static async deletePrize(data) {
    return await network.delete('prize', data, {});
  }

  static async exportPrizeList(data, config) {
    return await network.post('prize/filter/download/report', data, config);
  }

  static async getCharacteristics() {
    return await network.get('prize/characteristics/list', null, {});
  }

  static async getHandlers() {
    return await network.get('prize/handler/list', null, {});
  }
}