import network from "@/api/network";

export default class User {
  static async login(data) {
    return await network.post('user/login', data, {});
  }

  static async loginConfirm(data) {
    return await network.post('user/login', data, {});
  }

  static async fetchUser() {
    return await network.get('user/current', null, {});
  }
}