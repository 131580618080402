import axios from 'axios'
import api from '@/api'
import router from "@/router";

const state = {
  currentUser: null,
  isAuth: false,
  error: null,
  token: null,
};

const getters = {
  GET_CURRENT_USER(state) {
    return state.currentUser;
  },
  GET_IS_AUTH(state) {
    return state.isAuth;
  },
  GET_ERROR(state) {
    return state.error;
  },
};

const mutations = {
  SET_CURRENT_USER(state, payload) {
    state.currentUser = payload;
  },
  SET_IS_AUTH(state, payload) {
    localStorage.setItem("auth", JSON.stringify(payload));
    state.isAuth = JSON.parse(localStorage.getItem("auth"));
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_TOKEN(state, token) {
    if (!token) {
      token = null;
    }
    state.token = token;
    if (token !== null) {
      window.$cookies.set("token", token);
      axios.defaults.headers.common['Access-Token'] = token;
    } else {
      window.$cookies.remove("token");
      axios.defaults.headers.common['Access-Token'] = '';
    }
  }
};

const actions = {
  async login({commit, dispatch}, payload) {
    commit({type: 'SET_PRELOADER_INCR'}, { root: true });
    commit('SET_ERROR', null);
    let res = await api.user.login(payload);
    if (res.isSuccess) {
      commit('SET_TOKEN', res.result.access_token)
      commit("SET_IS_AUTH", true);
      dispatch('fetchUser');
      router.push("/");
    } else {
      commit('SET_ERROR', res.error.getMessage());
    }
    commit({type: 'SET_PRELOADER_DECR'}, { root: true });
    return res
  },
  redirectLogin({commit, dispatch}, payload) {
    if (payload) {
      commit('SET_TOKEN', payload);
      commit("SET_IS_AUTH", true);
      dispatch("fetchUser");
      commit({type: 'SET_PRELOADER_DECR'}, { root: true });
      router.push("/");
    }
  },
  async loginConfirm({commit, dispatch}, payload) {
    commit({type: 'SET_PRELOADER_INCR'}, { root: true });
    commit('SET_ERROR', null);
    let res = await api.user.login(payload);
    if (res.isSuccess) {
      commit('SET_TOKEN', res.result.access_token)
      commit("SET_IS_AUTH", true);
      dispatch('fetchUser');
      router.push("/");
    } else {
      commit('SET_ERROR', res.error.getMessage());
    }
    commit({type: 'SET_PRELOADER_DECR'}, { root: true });
    return res
  },
  async fetchUser({commit, dispatch}) {
    commit({type: 'SET_PRELOADER_INCR'}, { root: true })
    let res = await api.user.fetchUser()
    if (res.isSuccess) {
      commit('SET_CURRENT_USER', res.result);
    } else {
      dispatch('logout');
    }
    commit({type: 'SET_PRELOADER_DECR'}, { root: true });
    return res;
  },
  async logout({commit}) {
    commit('SET_TOKEN', null)
    commit("SET_IS_AUTH", false);
    commit("SET_CURRENT_USER", null);
    localStorage.clear();
    router.push("/login");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
