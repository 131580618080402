import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Главная",
    component: () => import("@/views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/Login.vue"),
    meta: {
      layout: "auth-layout",
      requiresGuest: true,
    },
  },
  {
    path: "/confirm",
    name: "confirm",
    component: () => import("@/views/Auth/LoginConfirm.vue"),
    meta: {
      layout: "auth-layout",
      requiresGuest: true,
    },
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import("@/views/Auth/PageNotFound.vue"),
    meta: {
      layout: "auth-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isAuth = localStorage.auth;
  if (requiresAuth && !isAuth) {
    next("/login");
  } else if (requiresGuest && isAuth) {
    next("/");
  } else {
    next();
  }
});

export default router;