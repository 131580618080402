import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import ru from 'vuetify/lib/locale/ru'

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'ru'
    },
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'md',
    lang: {
      locales: { ru },
      current: 'ru',
    },
  },
});
